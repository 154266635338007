import React, { ReactElement } from "react"
import Button from "../../components/design-system/Button"
import T from "@mui/material/Typography"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Skeleton from "../../components/Skeleton"
import { styled } from "@mui/material/styles"
import { navigate } from "gatsby"
import Link from "../../components/Link"
import HelpBox from "../../components/HelpBox"
import SEO from "../../components/SEO"
import useUserWithSignInRedirect from "../../hooks/useUserWithSignInRedirect"
import { formatDate, InvalidDateError } from "@ap-frontend/utility"
import { email } from "@ap-frontend/questions"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ButtonWrapper from "../../components/ButtonWrapper"
import Box from "@mui/material/Box"

let deadlineDate

const BR = styled("br")(() => ({}))

export const OnboardingForm = (): ReactElement => {
  const { user, profile, activity, updateActivity } =
    OnboardingForm.useUserWithSignInRedirect()
  const isLoading = !user || profile === undefined || activity === undefined

  const completeOnboarding = async () => {
    if (!activity?.completedOnboarding) {
      await updateActivity("completedOnboarding", true)
      return await navigate("/application/details", {
        replace: true,
        state: { viewingFirstTime: true },
      })
    }
    return await navigate("/application", { replace: true })
  }

  try {
    deadlineDate = formatDate(
      profile?.deadline as `${number}-${number}-${number}`
    )
  } catch (error) {
    if (error instanceof InvalidDateError) {
      deadlineDate = ""
    } else {
      throw error
    }
  }

  return (
    <>
      <T component="h1" variant="h1" data-test-id="welcome-header">
        {isLoading ? (
          <Skeleton sx={{ maxWidth: 600 }} />
        ) : (
          <>Welcome to My Cambridge Application</>
        )}
      </T>
      <T variant="body1" data-test-id="welcome-name">
        {isLoading ? (
          <Skeleton sx={{ maxWidth: 300 }} />
        ) : (
          <>Hello {profile?.forenames}.</>
        )}
      </T>
      <T variant="body1">
        {isLoading ? (
          <>
            <Skeleton sx={{ maxWidth: 500 }} />
            <Skeleton sx={{ maxWidth: 400 }} />
          </>
        ) : (
          <>
            We&apos;re going to ask you some extra questions. This is so that we
            have the best
            <BR sx={{ display: { xs: "none", sm: "block" } }} />
            information to assess your application.
          </>
        )}
      </T>
      <T component="h2" variant="h4">
        {isLoading ? (
          <Skeleton sx={{ maxWidth: 300 }} />
        ) : (
          <>Completing your application</>
        )}
      </T>
      <List component="ul" className="MuiList-unordered">
        <ListItem>
          <T variant="body1" component="span">
            {isLoading ? (
              <Skeleton sx={{ maxWidth: 400 }} />
            ) : (
              <>It should take about one hour to complete.</>
            )}
          </T>
        </ListItem>
        <ListItem>
          <T variant="body1" component="span">
            {isLoading ? (
              <Skeleton sx={{ maxWidth: 350 }} />
            ) : (
              <>Your answers are saved automatically.</>
            )}
          </T>
        </ListItem>
        <ListItem>
          <T variant="body1" component="span">
            {isLoading ? (
              <Skeleton sx={{ maxWidth: 400 }} />
            ) : (
              <>You can change your answers before you submit them.</>
            )}
          </T>
        </ListItem>
        <ListItem>
          <T variant="body1" component="span">
            {isLoading ? (
              <Skeleton sx={{ maxWidth: 450 }} />
            ) : (
              <>
                Sign out and return to your application at any time before the
                deadline.
              </>
            )}
          </T>
        </ListItem>
      </List>
      <Box data-test-id="deadline-box">
        {isLoading ? (
          <Skeleton sx={{ maxWidth: 550 }} />
        ) : deadlineDate ? (
          <HelpBox data-test-id="deadline-help-box">
            <T variant="body1">
              The deadline for your application is{" "}
              <strong data-test-id="deadline-date">
                6pm (UK time) on {deadlineDate}
              </strong>
            </T>
          </HelpBox>
        ) : (
          <T variant="body1" data-test-id="no-deadline-date">
            <Link href="https://www.undergraduate.study.cam.ac.uk/applying/dates-and-deadlines">
              Check your application deadline
            </Link>
          </T>
        )}
      </Box>
      <T component="h2" variant="h4">
        {isLoading ? (
          <Skeleton sx={{ maxWidth: 250 }} />
        ) : (
          <>Before you start</>
        )}
      </T>
      <List component="ul" className="MuiList-unordered">
        <ListItem>
          <T variant="body1" component="span">
            {isLoading ? (
              <Skeleton sx={{ maxWidth: 350 }} />
            ) : (
              <>{"You'll"} need a passport-style photo of yourself.</>
            )}
          </T>
        </ListItem>
        <ListItem>
          <T variant="body1" component="span">
            {isLoading ? (
              <Skeleton sx={{ maxWidth: 550 }} />
            ) : (
              <>
                You might need to provide other documents depending on your
                circumstances.
              </>
            )}
          </T>
        </ListItem>
        <ListItem>
          <T variant="body1" component="span">
            {isLoading ? (
              <>
                <Skeleton sx={{ maxWidth: 600 }} />
                <Skeleton sx={{ maxWidth: 250 }} />
              </>
            ) : (
              <>
                You might need to pay an application fee, if {"you're"} applying
                from outside of the United Kingdom, Ireland, Channel Islands or
                Isle of Man.
              </>
            )}
          </T>
        </ListItem>
      </List>
      <Accordion>
        <AccordionSummary aria-controls="provide-accordion-details">
          {isLoading ? (
            <Skeleton sx={{ width: 350, maxWidth: 350 }} />
          ) : (
            <>What documents might I need to provide?</>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <T variant="body2">You might need to provide:</T>
          <List component="ul" className="MuiList-unordered">
            <ListItem>
              <T variant="body2">
                a high school transcript. This depends on the qualifications you
                have.
              </T>
            </ListItem>
            <ListItem>
              <T variant="body2">
                a university transcript, if you have previously studied at
                university.
              </T>
            </ListItem>
          </List>
          <T variant="body2">
            {"We'll"} tell you if you need to provide a transcript. Allow enough
            time to get the transcript from your school, college or university.
          </T>
        </AccordionDetails>
      </Accordion>
      <T component="h2" variant="h4">
        {isLoading ? (
          <Skeleton sx={{ maxWidth: 250 }} />
        ) : (
          <>Get help with your application</>
        )}
      </T>
      <T variant="body1">
        {isLoading ? (
          <>
            <Skeleton sx={{ maxWidth: 750 }} />
            <Skeleton sx={{ maxWidth: 250 }} />
          </>
        ) : (
          <>
            {"We're"} happy to help. Email{" "}
            <Link href={`mailto:${email}`}>{email}</Link> if you have any
            queries or
            <BR sx={{ display: { xs: "none", sm: "block" } }} />
            technical issues.
          </>
        )}
      </T>
      <ButtonWrapper>
        {isLoading ? (
          <Skeleton variant="rectangular" sx={{ maxWidth: 150, height: 40 }} />
        ) : (
          <Button
            onClick={completeOnboarding}
            data-test-id="complete-onboarding-btn"
          >
            Start your application
          </Button>
        )}
      </ButtonWrapper>
    </>
  )
}

// Setting these as static members allows us to mock during component testing
OnboardingForm.useUserWithSignInRedirect = useUserWithSignInRedirect

const WelcomePage = (): ReactElement => {
  return (
    <>
      <WelcomePage.SEO title="Welcome" />
      <OnboardingForm />
    </>
  )
}

// Setting these as static members allows us to mock during component testing
WelcomePage.SEO = SEO

export default WelcomePage
